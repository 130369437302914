
//
// Variables
// 
// If you define variables first, you can then run @import bootstrap - and the defined
//  variables here will override those in the import. This allows you to customize

//http://colorschemedesigner.com/csd-3.5/#3v21T8j8399tX

$fontStack:             Helvetica, sans-serif;
$primaryColorLight:             #f2f2f2;
$primaryColorDark:              #1d2326;
$secondaryColorLight:           #bcd3e1;
$secondaryColorDark:            #162930;
$primaryColorDarkAccent:        #dd8018;
$secondaryColorDarkAccent:      #004949;

$textColorDark:         #031117;
$textColorLight:        #e1f0f9;
$textColorDarkAccent:   #e17700;
$textColorDarkLink:     #3e555f;

$mediumCutoff:          900px;

$sidebar-link-color:    $textColorLight;
$sidebar-color-bg:      $primaryColorDark;
$sidebar-active-bg:     $secondaryColorDark;
$sidebar-active-color:  $sidebar-link-color; //darken($sidebar-link-color, 40%);


//
// Bootstrap Variables that I'm altering
//

$navbar-inverse-bg:                     $primaryColorDark; // #222;
$screen-sm:                             $mediumCutoff; // 768px;
$body-bg:                               $primaryColorLight;

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           lighten($gray-base, 93.5%); // #eee

$brand-primary:         darken(#428bca, 6.5%); // #337ab7
$brand-success:         #5cb85c;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;

// Reset inverted navbar basics
$navbar-inverse-color:                      lighten($gray-light, 15%);
$navbar-inverse-bg:                         $primaryColorDark; //#222;
$navbar-inverse-border:                     darken($navbar-inverse-bg, 80%);

// Inverted navbar links
$navbar-inverse-link-color:                 $textColorLight; //lighten($gray-light, 15%);
$navbar-inverse-link-hover-color:           darken($navbar-inverse-link-color, 40%);//                                    //#fff;
$navbar-inverse-link-hover-bg:              transparent;
$navbar-inverse-link-active-color:          $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 10%);
$navbar-inverse-link-disabled-color:        #444;
$navbar-inverse-link-disabled-bg:           transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color:          #fff;
$navbar-inverse-brand-hover-bg:             transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg:            #333;
$navbar-inverse-toggle-icon-bar-bg:         #fff;
$navbar-inverse-toggle-border-color:        #333;

$link-color:                                $textColorDarkAccent;                                 //$brand-primary;
$link-hover-color:                          orange;// darken($link-color, 30j);               // 15%

$nav-link-hover-bg:                         $secondaryColorDark;                      //$gray-lighter;


$state-success-bg:                          $secondaryColorLight; //#dff0d8;
$alert-success-bg:                          $state-success-bg;

$state-success-text:                        $textColorDarkAccent; //#3c763d;
$alert-success-text:                        $state-success-text;


.sidebar > .nav li {
    background-image: url("/images/navButton.png");
    background-repeat: repeat-x;
    //background-color: blue;
}

a {
    font-weight: bold;
}

.alert {
    font-weight: bold;
}

//
// Import Bootstrap (and compass)
//
@import "bootstrap";


.subList {
    padding-left: 40px;
}

/*
.sidebar .nav ul.nav {
    display:none;
}

.sidebar .nav>.active>ul.nav {
    display:block;
}
*/


.truncateComments {
   width:250px;
   overflow:hidden;
   white-space:nowrap;
   text-overflow: ellipsis;
}


//
//
// Custom CSS on top of bootstrap below
//
//


.sidebar a {
    color: $sidebar-link-color;
}


.nav-border-custom {
    min-height: 5px;
    background-color: $primaryColorDarkAccent;
}



.sidebar ul{
}

//
// Stuff from the Default Bootstrap Template Example
//
//
body {
    // skip the headerer area
    padding-top: 55px;
}


/*
* Global add-ons
*/

.sub-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

/*
* Top navigation
* Hide default border to remove 1px line.
*/
.navbar-fixed-top {
    border: 0;
}

/*
* Sidebar
*/

/* Hide for mobile, show later */
.sidebar {
    display: none;
}
@media (min-width: $mediumCutoff ) {
    .sidebar {
        position: fixed;
        top: 50px;
        bottom: 0;
        left: 0;
        z-index: 1000;
        display: block;
        padding: 20px;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
        background-color: $sidebar-color-bg;
        border-right: 1px solid #eee;
    }
}

/* Sidebar navigation */
.nav-sidebar {
    margin-right: -21px; /* 20px padding + 1px border */
    margin-bottom: 20px;
    margin-left: -20px;
}
.nav-sidebar > li > a {
    padding-right: 20px;
    padding-left: 20px;
}
.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
    color: $sidebar-active-color;
    background-image: url("/images/navButtonActive.png");
    background-repeat: repeat-x;
    background-color: red;
//    background-color: $sidebar-active-bg;
}


/*
* Main content
*/

.main {
    padding: 20px;
}

@media (min-width: $mediumCutoff) {
    .main {
    padding-right: 40px;
    padding-left: 40px;
    }
}

.main .page-header {
    margin-top: 0;
}


.narrowify {
    width:100%;
}

.largify {
    width: 100%;

}

.truncateTxtField {

       width:30px;
       overflow:hidden;
       white-space:nowrap;
       text-overflow: ellipsis;
}

.forceScroll {
    height: 300px;
    overflow:hidden;
    overflow-y:scroll;
    overflow-x:hidden;
}


@media (min-width: $mediumCutoff) {
    .narrowify {
        width: 400px;
    }

    .largify {
        width: 700px;
    }
    
    .truncateTxtField {
       width:250px;
       overflow:hidden;
       white-space:nowrap;
       text-overflow: ellipsis;
    }

}


// 
// Footer Stuff
//

.footer {
    background-color: $primaryColorDark;
    height: 25px;
}

#wrap {
    padding-bottom: 25px;
}

.sidebar {
    padding-bottom: 25px;
}

.footer-border-custom {
    min-height: 5px;
    background-color: $secondaryColorDarkAccent;
}

.caption-table th {
    width: 100px;
    color: $secondaryColorDarkAccent;
}

.span-secondary-color-dark-accent {
    color: $secondaryColorDarkAccent;
}

.span-bold {
    font-weight: bold;
}




